.lease-payments {
    opacity: 0;
    animation: fadeInRight 1s forwards 0.5s;
}

.lease-payments .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.lease-payments-table-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.lease-payments-table-responsive {
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    border: 1px solid #dfe3ea;
}

.lease-payments-table-card .no-payments-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.lease-payments-table {
    color: #333;
    width: 100%;
    background-color: white;
}

.lease-payments-table thead {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #FCFCFC;
}

.lease-payments-table thead th,
.lease-payments-table tbody td {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
    font-size: 15px;
    color: #333;
}

.lease-payments-table tbody tr {
    height: 60px;
}

.lease-payments-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.lease-payments-table tbody tr.aktiv {
    background-color: #ffffff;
}

.lease-payments-table .status-badge-paid {
    background-color: #d4f4dd;
    color: #206938;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.lease-payments-table .status-badge-unpaid {
    background-color: #e9eaec;
    color: #5a5c61;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.lease-payments-table .status-badge-overdue {
    background-color: #ffd7d1;
    color: #b42318;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.lease-payments-table .status-badge-due-today {
    background-color: #d1e9ff;
    color: #0059b2;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.settings-icon {
    cursor: pointer;
}

.expandable-content {
    background-color: #f9f9f9;
}

.expanded-content-box {
    padding: 15px;
    text-align: start;
}

.lease-payments-table .remark {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
}

.lease-payments-table .cheque-number {
    display: block;
    font-size: 0.775rem;
    color: #666;
}

/* SKELETON LOADER */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    display: inline-block;
    animation: pulse 1.5s infinite;
}

.lease-payments .btn-create-payment {
    font-size: 14px;
    background-color: #FCEDCB;
    color: black;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.lease-payments .btn-create-payment:hover {
    background-color: rgb(239, 228, 201);
    cursor: pointer;
    transform: scale(0.95);
}

/* Mobile Payment Cards */
.mobile-payments-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.mobile-payment-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    animation: fadeInUp 0.5s forwards;
}

.mobile-payment-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    cursor: pointer;
    position: relative;
}

.mobile-payment-date {
    flex: 2;
}

.mobile-payment-amount {
    flex: 1.3;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mobile-payment-amount-text {
    font-weight: 600;
    font-size: 15px;
}

.mobile-payment-status {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 500;
}

.mobile-payment-purpose {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
}

.mobile-payment-expand {
    flex: 0 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    margin-left: 8px;
    margin-top: 2px;
}

.mobile-payment-label {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
}

.mobile-payment-subtext {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.mobile-icon {
    font-size: 14px;
    color: #666;
}

.mobile-payment-details {
    padding: 0 12px 15px 12px;
    border-top: 1px solid #f0f0f0;
    background-color: #fafafa;
}

.mobile-payment-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-top: 12px;
}

.mobile-payment-info-item {
    margin-bottom: 8px;
}

.mobile-payment-info-label {
    font-size: 12px;
    color: #666;
    margin-bottom: 4px;
}

.mobile-payment-info-value {
    font-size: 14px;
    font-weight: 500;
}

.mobile-payment-info-subtext {
    display: block;
    font-size: 12px;
    color: #666;
    font-weight: normal;
}

.mobile-payment-remark {
    margin-top: 16px;
}

.mobile-payment-document {
    margin-top: 16px;
}

.status-text-paid {
    color: #206938;
}

.status-text-unpaid {
    color: #5a5c61;
}

.status-text-overdue {
    color: #b42318;
    font-weight: 600;
}

.status-text-due-today {
    color: #0059b2;
    font-weight: 600;
}

.mobile-payment-actions {
    margin-top: 12px;
}

.mobile-payment-actions .custom-button {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}

/* Status badge colors for mobile */
.status-badge-paid {
    background-color: #d4f4dd;
    color: #206938;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    display: inline-block;
    font-weight: 500;
}

.status-badge-unpaid {
    background-color: #e9eaec;
    color: #5a5c61;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    display: inline-block;
    font-weight: 500;
}

.status-badge-overdue {
    background-color: #ffd7d1;
    color: #b42318;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    display: inline-block;
    font-weight: 500;
}

.status-badge-due-today {
    background-color: #d1e9ff;
    color: #0059b2;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    display: inline-block;
    font-weight: 500;
}

/* Kleiner Badge für mobile Ansicht */
.status-badge-mobile {
    padding: 0.2rem 0.6rem;
    font-size: 0.75rem;
    white-space: nowrap;
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.6;
    }
}
