/* Additional styles for the login page */
.leftPanel, .rightPanel {
    background-color: white;
    height: calc(var(--vh, 1vh) * 100);
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.leftPanel {
    color: white;
    background-image: url("../../assets/images/login-background.webp");
    background-size: cover;
    animation: fadeInUp 1s forwards;
}

.leftPanel h4 {
    font-size: 30px;
    font-weight: bold;
}

.rightPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    animation: fadeInRight 1s forwards;
}

.rightPanel h4 {
    font-size: 34px;
    font-weight: bold;
}

.imagePlaceholder {
    width: 100%;
    height: 200px;
    background-color: #ddd;
    margin-bottom: 20px;
}

.loginForm {
    width: 50%;
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
}

.loginQuote {
    width: 70%;
    align-self: center;
    opacity: 0;
    animation: fadeInLeft 1s forwards 0.5s;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
}

.footerCopyright {
    color: #7c7b7b;
    font-size: 14px;
    text-align: center;
    width: 100%;
}

.footerLogo {
    position: absolute;
    bottom: 10px;
    right: 20px;
}

/* Additional responsive adjustments */
@media (max-width: 768px) {
    .leftPanel {
        display: none;
    }
    .rightPanel {
        background: white;
    }
    .loginForm {
        width: 80%;
    }
}

.btnPrimary {
    background-color: #007bff;
    border: none;
}

.formControl {
    border-radius: 0.25rem;
}

.languageSelectContainer {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
}

.languageSelect {
    width: 100% !important;
    background-color: transparent !important;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}