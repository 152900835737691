/* DocumentTable.css */
.document-table {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.document-table thead {
    height: 3.125rem;
}

.document-table thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 0.9375rem;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.document-table tbody tr {
    height: 3.75rem;
}

.document-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.document-table tbody tr.aktiv {
    background-color: #ffffff;
}

.document-table tbody tr.entwurf {
    background-color: #FFFCF5;
}

.document-table tbody tr.inaktiv {
    background-color: #FFF6F7;
}

.document-table tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 0.9375rem;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

.email-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.email-action {
    color: #6B7280;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.email-action:hover {
    cursor: pointer;
    opacity: 0.5;
}

.email-action span {
    margin-right: 4px;
}

.sales-agent img {
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.settings-icon:hover {
    cursor: pointer;
    opacity: 0.5;
}

.document-status-badge {
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    white-space: nowrap;
}

.document-status-badge.accepted {
    background-color: #cff4fc;
    color: #007bff;
}

.document-status-badge.in_review {
    background-color: #fff3cd;
    color: #856404;
}

.document-status-badge.declined {
    background-color: #f8d7da;
    color: #721c24;
}

.document-table-responsive {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 992px) {
    .document-table tbody tr {
        transition: none;
    }
    
    .document-table tbody tr:hover {
        transform: none;
    }
    
    .document-table {
        min-width: 800px;
    }
}

/* Mobile responsive styles */
@media (max-width: 767px) {
    .document-table-responsive {
        overflow-x: auto;
        margin: 0; /* Remove negative margins */
        padding: 0; /* Remove padding to maximize space for table */
        -webkit-overflow-scrolling: touch;
        width: 100%; /* Use normal width */
    }
    
    .white-card .document-table-responsive {
        margin: 0; /* Reset specific margins for white-card */
        width: 100%;
    }
    
    .white-card {
        padding-left: 5px !important; /* Minimal padding to keep content inside card */
        padding-right: 5px !important;
    }
    
    .document-table {
        min-width: 800px;
        margin-bottom: 1rem;
        display: table;
    }
    
    /* Keep table structure intact for mobile scrolling */
    .document-table thead {
        display: table-header-group;
    }
    
    .document-table tbody {
        display: table-row-group;
    }
    
    .document-table tr {
        display: table-row;
    }
    
    .document-table th,
    .document-table td {
        display: table-cell;
    }
    
    .document-table tbody tr {
        height: 3.75rem;
        margin-bottom: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
    
    .document-table tbody tr.in_review {
        background-color: #FFFCF5;
        border-left: none;
    }
    
    .document-table tbody tr.accepted {
        background-color: #F0FFF4;
        border-left: none;
    }
    
    .document-table tbody tr.declined {
        background-color: #FFF6F7;
        border-left: none;
    }
    
    .document-table tbody tr td {
        padding: 0.5rem 1rem;
        text-align: left;
        position: relative;
        padding-left: 1rem;
        border-bottom: 1px solid #eee;
    }
}