.modal-backdrop {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    margin-top: 0 !important;
}

.input-modal-title {
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
}

.input-modal-subtitle {
    font-size: 16px !important;
    font-style: normal !important;
}

.modal-box {
    width: 500px;
    min-width: 400px;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    color: black;
}

@media (max-width: 768px) {
    .modal-box {
        min-width: 90%;
        width: 90%;
    }
}

.close-btn {
    margin-left: auto;
    background-color: #131313;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
