.lease-detail-page .headline {
    font-weight: 600;
}

.lease-detail-page {
    padding-bottom: 2rem;
}

/* Header Card */
.lease-header-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.lease-property-badge {
    background-color: #E6F4FF;
    color: #0070FF;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 500;
}

.lease-dates-badge {
    display: flex;
    align-items: center;
    background-color: #F0F0F0;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 13px;
    color: #555;
}

.lease-id-text {
    font-size: 13px;
    color: #777;
}

/* Detail Cards */
.lease-detail-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    height: 100%;
}

.lease-detail-card .card-header {
    background-color: #FBFBFB;
    padding: 15px;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    align-items: center;
}

.lease-detail-card .card-body {
    background-color: white;
}

/* Override some component styles to fit our new design */
.lease-detail-card .title {
    margin: 0 !important;
    font-size: 16px !important;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .lease-header-card {
        padding: 15px;
    }
    
    .lease-detail-card .card-header {
        padding: 12px 15px;
    }

    .lease-detail-card .card-body {
        padding: 15px !important;
    }

    .lease-detail-page .headline {
        font-size: 1.4rem;
    }

    /* Reduced padding for white cards on mobile */
    .lease-detail-page .white-card {
        padding: 15px !important;
    }

    /* Make better use of horizontal space on mobile */
    .lease-detail-page .container,
    .lease-detail-page .row {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: -5px;
        margin-right: -5px;
    }

    .lease-detail-page .col-12 {
        padding-left: 8px;
        padding-right: 8px;
    }

    /* Reduce spacing between elements */
    .lease-detail-page .mb-4 {
        margin-bottom: 12px !important;
    }

    /* Reduce gap between grid rows */
    .lease-detail-page .gy-4 {
        --bs-gutter-y: 12px;
    }
}
