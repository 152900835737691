/* Property Sidebar Modal Styles */
.property-images-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.property-image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 10px;
}

.checkbox-label {
    font-size: 14px;
    margin-bottom: 0;
}

.image-upload-container {
    margin-bottom: 20px;
}

.section-title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 16px;
}

.sidebar-modal-content .row {
    margin-bottom: 15px;
} 