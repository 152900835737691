/* Styles für das Review-Document-Modal */

/* Allgemeine Styles */
.review-document-content {
  width: 100%;
}

/* Mobile-spezifische Anpassungen */
@media (max-width: 767px) {
  .modal-box {
    max-height: 90vh !important;
    width: 95% !important;
    overflow-y: auto !important;
  }

  .file-preview-container {
    max-height: 40vh !important;
    overflow-y: auto !important;
  }

  /* PDF-Viewer in der Größe begrenzen */
  .file-preview-container img,
  .file-preview-container .rpv-core__viewer {
    max-height: 40vh !important;
  }
  
  /* Spezielle Anpassung für Proof-of-Address Dokumente */
  .investor-address-section {
    margin-top: 1rem;
  }
  
  /* Verbesserte Scroll-Fähigkeit im Dokument */
  .rpv-core__viewer {
    overflow-y: auto !important;
  }
}
