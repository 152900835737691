.tenant-leases-section {
    opacity: 0;
    animation: fadeInUp 1s forwards;
    transition: transform 0.3s ease;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.white-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.tenant-leases-section .table {
    margin-bottom: 0;
}

.tenant-leases-section .table thead th {
    border-top: none;
    background-color: #fbfbfb;
    color: #495057;
    font-weight: 500;
    padding: 12px 16px;
    font-size: 0.9rem;
}

.tenant-leases-section .table tbody td {
    padding: 12px 16px;
    vertical-align: middle;
}

.tenant-leases-section .table tr:hover {
    background-color: #f8f9fa;
}

.clickable-row {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.clickable-row:hover {
    background-color: #f0f7ff !important;
}

.tenant-leases-section .badge {
    padding: 6px 10px;
    font-weight: 500;
    font-size: 0.75rem;
}

/* Mobile-Kartenansicht */
.lease-mobile-view {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.lease-card {
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.lease-card.clickable {
    cursor: pointer;
}

.lease-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.lease-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e9ecef;
}

.lease-card-status .badge {
    font-size: 0.75rem;
    padding: 6px 10px;
}

.lease-view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
    transition: all 0.2s ease;
}

.lease-view-button:hover {
    color: #0056b3;
}

.lease-card-detail {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f5f5f5;
}

.lease-card-detail:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.lease-card-label {
    width: 40%;
    color: #6c757d;
    font-size: 0.85rem;
    font-weight: 500;
}

.lease-card-value {
    width: 60%;
    font-size: 0.9rem;
    font-weight: 500;
    color: #343a40;
    word-break: break-word;
}

/* Responsive Anpassungen */
@media (max-width: 767.98px) {
    .white-card {
        padding: 15px;
    }
    
    .tenant-leases-section .table thead th,
    .tenant-leases-section .table tbody td {
        padding: 10px 12px;
        font-size: 0.85rem;
    }
    
    .tenant-leases-section .btn-sm {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
    }
    
    .lease-card {
        padding: 12px;
    }
    
    .lease-card-label, .lease-card-value {
        font-size: 0.85rem;
    }
} 