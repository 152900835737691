.unit-detail-page .headline {
    font-weight: 600;
}

.unit-detail-avatar {
    width: 150px;
    height: 150px;
    border-radius: 75px;
}


.pm-contract-sign-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    text-align: center;
}

.pm-contract-sign-message {
    margin-bottom: 20px;
    font-size: 1rem;
}

.form-check.form-switch {
    align-items: center;
}

/* Document Tabs Styles */
.documents-container {
    margin-top: 2rem;
}

.document-tabs {
    display: flex;
    border-bottom: 1px solid #e9ecef;
    margin-bottom: 0;
    overflow-x: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1px;
}

.document-tabs::-webkit-scrollbar {
    display: none;
}

.document-tab {
    padding: 12px 20px;
    margin-right: 6px;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    font-weight: 500;
    transition: all 0.25s ease;
    white-space: nowrap;
    color: #6c757d;
    position: relative;
    user-select: none;
}

.document-tab:hover {
    background-color: rgba(0, 123, 255, 0.05);
    color: #495057;
}

.document-tab.active {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #e9ecef;
    border-bottom-color: #fff;
    margin-bottom: -1px;
}

.document-tab.active:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #007bff;
    border-radius: 3px 3px 0 0;
    animation: tabActivate 0.3s ease;
}

@keyframes tabActivate {
    from { 
        transform: scaleX(0);
        opacity: 0;
    }
    to { 
        transform: scaleX(1);
        opacity: 1;
    }
}

.document-tab-content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: opacity 0.25s ease, transform 0.25s ease;
    animation: fadeIn 0.3s ease;
    transform-origin: top center;
}

.tab-pane-content {
    animation: fadeIn 0.3s ease;
}

/* Anpassungen für Foto-Tab */
.tab-pane-content .unit-pictures-container {
    padding: 0;
    margin: 0;
    box-shadow: none;
    background-color: transparent;
}

.tab-pane-content .unit-pictures-container .card {
    border-radius: 8px;
    overflow: hidden;
}

.tab-pane-content .unit-pictures-container .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

@keyframes fadeIn {
    from { 
        opacity: 0;
        transform: translateY(-5px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

.document-tab-badge {
    background-color: #e9ecef;
    color: #6c757d;
    font-size: 0.75rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-left: 6px;
    font-weight: bold;
}

/* Runde Ecken für FileUploadSection */
.document-tab-content .file-upload-section {
    border-radius: 8px;
    overflow: hidden;
}

.document-tab-content .file-upload-section .card {
    border-radius: 8px;
}

.document-tab-content .file-upload-section .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

@media (max-width: 767.98px) { 
    .unit-detail-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
    }
    
    .document-tab {
        padding: 10px 16px;
        font-size: 0.9rem;
    }
    
    .document-tab-content {
        padding: 15px;
    }
}
