/* InvestorSidebarModal.css */

/* Zusätzliche Styles für das Tenant Modal */
.error-container {
    max-height: 60px;
    overflow-y: auto;
}

/* Stellt sicher, dass der scrollbare Bereich genug Platz am Ende hat */
.sidebar-modal-scrollable-content {
    padding-bottom: 80px;
}

/* Korrigierte Version für den Button-Container */
.store-investor-btn {
    position: absolute !important;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: white;
    padding: 20px;
    box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.05);
}